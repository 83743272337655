import React from "react"
import { navigate } from "gatsby"
import { Card, CardGroup } from "react-bootstrap"
import CustomButton from "../button"
import MessageIcon from "../../assets/circle-message.svg"
import PuzzleIcon from "../../assets/circle-puzzle.svg"
import MagnifyingGlassIcon from "../../assets/circle-magnifying-glass.svg"
import MonitorIcon from "../../assets/circle-monitor.svg"
import GearIcon from "../../assets/circle-gear.svg"
import HalfGearIcon from "../../assets/circle-half-gear.svg"

import "./ourProcess.css"

const OurProcess = () => {
  return (
    <div className="our-process">
      <h2>We’ll take it from here</h2>
      <p>
        Our team will take care of the hard work, so you can simply Compare n
        Save.
      </p>
      <CardGroup>
        <Card>
          <MessageIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Contact</Card.Title>
            <Card.Text>
              Select your preferred home loan option and connect with us to begin your application journey.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <PuzzleIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Understand</Card.Title>
            <Card.Text>
              During our initial chat, we’ll seek to understand your goals and
              specific requirements.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <MagnifyingGlassIcon className="card-icon" />

          <Card.Body>
            <Card.Title>Assess</Card.Title>
            <Card.Text>
              We’ll assess your borrowing capacity and suitability across our
              panel of trusted lenders.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <MonitorIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Present</Card.Title>
            <Card.Text>
              We’ll present competitive home loan options to suit your unique
              set of circumstances.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <GearIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Build</Card.Title>
            <Card.Text>
              Once you’re comfortable to proceed with your new home loan, we’ll
              prepare an application for your chosen lender.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <HalfGearIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Manage</Card.Title>
            <Card.Text>
              We’ll manage the entire process - from application to settlement.
              We’ll even provide you with regular reviews.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup className="card-action-group">
        <Card>
          <Card.Body>
            <Card.Title>
              Ready <br />
              to progress?
            </Card.Title>
            <CustomButton
              variant="secondary"
              className="our-process-button"
              onClick={() => navigate("/contact")}
            >
              Request a call back
            </CustomButton>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Like what Compare n Save stands for?</Card.Title>
            <CustomButton
              variant="secondary"
              className="our-process-button"
              onClick={() => {
                navigate("/contact")
              }}
            >
              Express interest in joining the team
            </CustomButton>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  )
}

export default OurProcess
